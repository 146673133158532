$primary: #52a329;

// Style anchor elements.
$link-color:#52a329;
$link-hover-color:#52a329;
$link-decoration: none;

// Settings for the `<body>` element.
$body-color: #323232;

// Typography
$font-family-sans-serif:
  "Rubik",
  // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  Arial,
  // Linux
  "Noto Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
  $font-size-root: 16px;